import React from 'react'

import Layout from '../hoc/layout/layout'
import SEO from '../hoc/seo'

const services = () => (
  <Layout>
    <SEO title="404: Not found" />
  </Layout>
)

export default services
